export class TableSearch {
  constructor() {
    this._inputs = []
  }

  static start() {
    if(!window.tableSearch) {
      window.tableSearch = new this();
      window.tableSearch.start();
    }
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.create();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  cleanup() {
    this._inputs
      .filter((el) => !el.isConnected)
      .forEach((el) => {
        el.removeEventListener('keyup', this.search, true);
        const pos = this._inputs.indexOf(el);
        this._inputs.splice(pos, 1);
      });
  }

  create() {
    const inputs = document.querySelectorAll(
      'input[data-table-search="table-search"]'
    );

    inputs.forEach((el) => {
      if(!this._inputs.includes(el)) {
        el.addEventListener('keyup', this.search);
        this._inputs.push(el);
      }
    })
  }

  debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  search = this.debounce((event) => {
    const input = event.target
    const filter = input.value
    const pattern = filter
          .split('')
          .map(char => {
            // Escape special regex characters
            return char.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
          })
          .join('.*');
    const regex = new RegExp(pattern, 'i'); // 'i' for case-insensitive

    const table = input.closest("table")
    const rows = table.querySelectorAll("tbody > tr ")
    const numCols = Number(input.attributes["data-table-search-columns"].value)
    // Loop through all table rows in tbody
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i]
      let rowVisible = false;
      // If the input is empty, show all rows
      if (!filter) {
        rowVisible = true;
      } else {
        const cols = row.getElementsByTagName('td');

        // Loop through all cells in the row
        for (let j = 0; j < numCols; j++) {
          const cell = cols[j];
          if(cell) {
            const content = cell.textContent || cell.innerText;
            if (regex.test(content)) {
              rowVisible = true;
              break; // Stop checking other cells if a match is found
            }
            const tooltips = Array.from(
              cell.querySelectorAll("span[data-original-title]")
            )
            const tooltipMatch = tooltips.find( (span) => {
              const text = span.attributes["data-original-title"].value
              return regex.test(text)
            })
            if(tooltipMatch) {
              rowVisible = true;
              break; // Stop checking other cells if a match is found
            }
          }
        }
      }

      // Show or hide the row based on the search query
      row.style.display = rowVisible ? '' : 'none';
    }
  }, 200)
}